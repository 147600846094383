import VueNativeSock from "vue-native-websocket-vue3";
import store from "./store";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import clickOutSide from "@/directives/clickOutSide";

import { createApp } from 'vue'
import App from './App.vue'
import mitt from 'mitt'
import { router } from './router/index'
const app = createApp(App);
app.use(store);
app.use(router);
app.use(VueNativeSock, process.env.VUE_APP_API_WEBSOCKET, {
  // connect manually in login, this url is just a placeholder
  reconnection: true,
  reconnectionDelay: 2000,
  store,
  format: 'json',
  connectManually: true,
})
window.emitter = mitt()
// directives
app.directive("click-outside", clickOutSide);
app.mount('#app')
