<template>
  <div class="toolbar" :style="{ backgroundColor: backgroundColor }">
    <div class="d-block">
      <button @click="handleDrawClick" type="button" class="draw-button">
        <img
          class="draw-icon"
          :src="
            !draw
              ? isDarkBackground
                ? drawIcon
                : drawIconDark
              : isDarkBackground
              ? drawIconHide
              : drawIconHideDark
          "
          alt="draw-icon"
          :title="draw ? 'Hide drawing' : 'Draw'"
        />
      </button>
    </div>

    <div
      v-if="!draw && !hideBackgroundColorControls"
      class="background-color-section"
    >
      <button
        v-for="color in backgroundColors"
        :key="color"
        @click="selectBackgroundColor(color)"
        :class="{ selected: backgroundColor === color }"
        :style="{ backgroundColor: color }"
        class="background-color-button"
      ></button>
    </div>

    <div v-if="draw" class="colors-section">
      <div class="d-inline-block">
        <label @click.prevent="undoDrawStroke()">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="27"
            :fill="isDarkBackground ? 'white' : 'black'"
            class="bi bi-arrow-counterclockwise"
            viewBox="2 -3 16 25"
          >
            <path
              fill-rule="evenodd"
              d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z"
            />
            <path
              d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z"
            />
          </svg>
        </label>
      </div>
      <div
        v-for="colorItem in colorOptions"
        :key="colorItem"
        class="d-inline-block"
      >
        <input type="radio" name="color" id="color-item" :value="colorItem" />
        <label for="color-item">
          <span
            :class="{ selectedStyle: colorItem === color }"
            @click="selectColor(colorItem)"
            :style="{ backgroundColor: colorItem }"
            class="color-item span-border"
          >
          </span>
        </label>
      </div>
    </div>
    <button
      class="leave"
      v-if="!inVideoCall && showVideoChat"
      @click="joinCall()"
    >
      <img class="icon" :src="leave" alt="" title="Start video call" />
    </button>

    <button
      v-if="!showTextChat && !textChatHiddenByAdmin"
      class="chat-circle-button"
      @click="openChat"
    >
      <span v-if="unreadTextMessage" class="new-message">.</span>
      <img
        class="chat-circle-button-img"
        src="../assets/chat-circle.png"
        alt="open-chat"
      />
    </button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import drawIcon from "../assets/draw-icon.png";
import drawIconHide from "../assets/draw-icon-hide.png";
import drawIconDark from "../assets/draw-icon-dark.png";
import drawIconHideDark from "../assets/draw-icon-hide-dark.png";
import leave from "../assets/leave_call.svg";

export default {
  name: "Toolbar",
  data() {
    return {
      drawIcon,
      drawIconHide,
      drawIconDark,
      drawIconHideDark,
      leave,
      backgroundColors: ["#000000", "#7C7C7C", "#FFFFFF"],
    };
  },
  computed: {
    defaultStrokes() {
      // Add some default strokes otherwise it crashes
      return Array.from({ length: this.colorOptions.length }, (_, i) => ({
        type: "dash",
        from: {
          x: 0,
          y: 0,
        },
        coordinates: [],
        color: this.colorOptions[i],
        width: 5,
        fill: false,
      }));
    },
    draw: {
      get() {
        return this.$store.state.draw;
      },
      set(value) {
        this.$store.commit("setDraw", value);
      },
    },
    colorOptions: {
      get() {
        return this.$store.state.colorOptions;
      },
      set(value) {
        this.$store.commit("setColorOptions", value);
      },
    },
    strokes: {
      get() {
        return this.$store.state.strokes;
      },
      set(value) {
        this.$store.commit("setStrokes", value);
      },
    },
    myStrokes: {
      get() {
        return this.$store.state.myStrokes;
      },
      set(value) {
        this.$store.commit("setMyStrokes", value);
      },
    },
    playerWidth: {
      get() {
        return this.$store.state.playerWidth;
      },
      set(value) {
        this.$store.commit("setPlayerWidth", value);
      },
    },
    playerHeight: {
      get() {
        return this.$store.state.playerHeight;
      },
      set(value) {
        this.$store.commit("setPlayerHeight", value);
      },
    },
    color: {
      get() {
        return this.$store.state.color;
      },
      set(value) {
        this.$store.commit("setColor", value);
      },
    },
    userEnabledDraw: {
      get() {
        return this.$store.state.userEnabledDraw;
      },
      set(value) {
        this.$store.commit("setUserEnabledDraw", value);
      },
    },
    backgroundColor: {
      get() {
        return this.$store.state.backgroundColor;
      },
      set(value) {
        this.$store.commit("setBackgroundColor", value);
      },
    },
    isDarkBackground() {
      const rgb = this.hexToRgb(this.backgroundColor);
      return rgb.r * 0.299 + rgb.g * 0.587 + rgb.b * 0.114 <= 186;
    },
    ...mapGetters([
      "showTextChat",
      "inVideoCall",
      "unreadTextMessage",
      "showVideoChat",
      "clientToken",
      "textChatHiddenByAdmin",
      "hideBackgroundColorControls",
    ]),
  },
  methods: {
    handleDrawClick() {
      this.$parent.handleResize();
      this.draw = !this.draw;
      this.userEnabledDraw = this.draw;

      if (this.draw) {
        this.$socket.sendObj({
          message: "REQUEST_BRUSH_STROKES",
          data: {},
        });
      }
      // Erase all strokes from remote players
      if (!this.draw) {
        this.myStrokes = this.defaultStrokes;
        this.strokes = this.defaultStrokes;

        this.$socket.sendObj({
          message: "NEW_BRUSH_STROKES",
          data: {
            strokes: {
              player_width: this.playerWidth,
              player_height: this.playerHeight,
              strokes: this.myStrokes, // Sending the default ones so we can know that which user stopped drawing. Filter out that color.
            },
          },
        });
      }
      setTimeout(() => {
        this.$emit("handle-resize");
      }, 700);
    },
    undoDrawStroke() {
      this.$emit("clicked-undo-draw-stroke", true);
    },
    openChat() {
      this.$store.commit("setShowTextChat", true);
      this.$store.commit("setTextChatHiddenByUser", false);
      this.$store.commit("setUnreadTextMessage", false);
      setTimeout(() => {
        this.$emit("handle-resize");
      }, 700);
    },
    joinCall() {
      this.$store.commit("setInVideoCall", true);
      window.emitter.emit("joinCall", true);
      setTimeout(() => {
        this.$emit("handle-resize");
      }, 700);
    },
    leaveCall() {
      this.$store.commit("setInVideoCall", false);
      setTimeout(() => {
        this.$emit("handle-resize");
      }, 700);
    },
    selectColor(color) {
      this.color = color;
    },
    selectBackgroundColor(color) {
      this.backgroundColor = color;
      this.$store.commit("setBackgroundColor", color);
    },
    hexToRgb(hex) {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
          }
        : null;
    },
  },

  mounted() {},
};
</script>

<style scoped>
.toolbar {
  position: absolute;
  bottom: 40px !important;
  left: 8px;
  justify-content: space-between;
  z-index: 13; /* Increase z-index to ensure it's above other elements */
  width: calc(100% - 16px);
}

.draw-icon {
  width: 24px;
  height: 24px;
}

.draw-button {
  position: absolute;
  top: -2px;
  left: 0;
  z-index: 2;
}

.action-btn-first {
  left: 30px;
  top: 4px;
}

.action-btn-second {
  left: 65px;
  top: 20px;
}

.action-buttons-class svg {
  width: 20px;
  height: 20px;
  color: white;
}

button:not(.leave) {
  margin: 0 4px;
  width: 36px;
  height: 26px;
}

button.leave {
  position: absolute;
  background-color: green;
  opacity: 0.85;
  padding: 14px 16px 15px;
  border-radius: 12px;
  top: -8px;
  right: 0;
  left: 0;
  width: 56px;
  margin: auto;
}

.chat-circle-button {
  position: absolute;
  right: 0;
  top: -2px;
  z-index: 12;
  width: 35px !important;
  height: 35px !important;
}
.chat-circle-button-img {
  width: 100%;
}

.new-message {
  color: red;
  font-size: 70px;
  margin: 0;
  padding: 0;
  position: absolute;
  top: -65px;
  right: -5px;
}

.colors-section {
  position: absolute;
  top: 25px;
  left: 10px;
  z-index: 2;
}

.colors-section label {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-right: 10px;
  cursor: pointer;
}
.colors-section label:hover span {
  transform: scale(1.25);
}
.colors-section label span {
  display: block;
  width: 100%;
  height: 100%;
  transition: transform 0.2s ease-in-out;
  border-radius: 50%;
}

.colors-section input[type="radio"] {
  display: none;
}
.selectedStyle {
  transform: scale(1.25);
  border: 2px solid white;
}

.colors-section label span.red {
  background: #db2828;
}

.colors-section label span.green {
  background: #21ba45;
}

.colors-section label span.yellow {
  background: #fbbd08;
}

@media (max-width: 991px) {
  .chat-circle-button {
    display: none;
  }
}

@media (max-width: 570px) {
  .colors-section {
    left: 9px !important;
  }

  .action-btn-first {
    left: 18px !important;
  }

  .colors-section label {
    margin-right: 6px !important;
  }
}

.background-color-section {
  display: flex;
  align-items: center;
  margin-left: 40px;
}

.background-color-button {
  width: 20px !important;
  height: 20px !important;
  margin: 2px !important;
  border: 1px solid #ccc !important;
  cursor: pointer;
  padding: 0 !important;
}

.background-color-button.selected {
  border-color: #2e58c7 !important;
  border-width: 2px !important;
}
</style>
